<template>
<div>
  <section>
    <b-card class="card-custom gutter-b">
      <template #header>
        <b-row class="w-100 d-flex align-items-center" no-gutters>
          <b-col xs=6>
            <h5 class="m-0">Subscriber List</h5>
          </b-col>
          <b-col xs=6 class="d-flex justify-content-end">
            <b-button variant="success" @click="doExportnewsletter()">Export</b-button>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col sm=12 class="mb-5">
          <b-row no-gutters class="justify-content-between">
            <b-col sm=12 md=3 class="mb-3 mb-sm-0">
              <b-input-group prepend="Search">
                <b-form-input v-model="pagingNewsletter.search" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!pagingNewsletter.search" @click="pagingNewsletter.search = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col sm=12 md=2 class="d-flex justify-content-end">
              <b-input-group prepend="Per Page">
                <b-select :options="opt.perPage" v-model="pagingNewsletter.perPage" />
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm=12 class="mb-5">
          <b-overlay :show="tableNewsletter.loading">
            <b-table show-empty small :fields="tableNewsletter.fields" :items="tableNewsletter.items" :current-page="pagingNewsletter.page" :sort-by.sync="pagingNewsletter.sortBy" :sort-desc.sync="pagingNewsletter.sortDesc" :sort-direction="pagingNewsletter.sortDirection" bordered striped hover responsive>
              <template #cell(no)="row">
                {{ (pagingNewsletter.perPage * (pagingNewsletter.page-1)) + row.index + 1 }}
              </template>
              <template #cell(content)="row">
                <div v-html="row.item.content" class="px-5" />
              </template>
              <template #cell(action)="row">
                <b-button size="sm" variant="danger" @click="doDeleteNewsletter(row.item.id,false)" title="Delete">
                  <b-icon icon="trash" />
                </b-button>
              </template>
            </b-table>
          </b-overlay>
        </b-col>
        <b-col sm=12>
          <b-row no-gutters class="w-100">
            <b-col sm=12 md=6 class="d-flex justify-content-center justify-content-sm-start mb-3 mb-sm-0">
              Showing {{pagingNewsletter.perPage}} data out of {{tableNewsletter.totalRows}}
            </b-col>
            <b-col sm=12 md=6 class="d-flex justify-content-center justify-content-sm-end">
              <b-pagination v-model="pagingNewsletter.page" :total-rows="tableNewsletter.totalRows" :per-page="pagingNewsletter.perPage"></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-modal header-bg-variant="warning" centered body-bg-variant="warning" id="cm-modal-delete-newsletter" size="sm" hide-footer hide-header>
      <div class="text-center">
        <h1 class="text-light">Warning</h1>
        <p class="text-center mb-3 text-light">Are You Sure to Delete this Data ?</p>
        <div class="d-flex justify-content-center">
          <b-button variant="light" @click="$bvModal.hide('cm-modal-delete')">Cancel</b-button>
          <b-button variant="danger" @click="doDeleteNewsletter(formNewsletter.deleteId,true)" class="ml-3">Delete!!!</b-button>
        </div>
      </div>
    </b-modal>
    <b-card class="card-custom gutter-b">
      <template #header>
        <b-row class="w-100 d-flex align-items-center" no-gutters>
          <b-col xs=6>
            <h5 class="m-0">Contact Form Message</h5>
          </b-col>
          <b-col xs=6 class="d-flex justify-content-end">
            <!-- <b-button variant="success" @click="doExportnewsletter()">Export</b-button> -->
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col sm=12 class="mb-5">
          <b-row no-gutters class="justify-content-between">
            <b-col sm=12 md=3 class="mb-3 mb-sm-0">
              <b-input-group prepend="Search">
                <b-form-input v-model="pagingContactus.search" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!pagingContactus.search" @click="pagingContactus.search = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col sm=12 md=2 class="d-flex justify-content-end">
              <b-input-group prepend="Per Page">
                <b-select :options="opt.perPage" v-model="pagingContactus.perPage" />
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm=12 class="mb-5">
          <b-overlay :show="tableContactus.loading">
            <b-table show-empty small :fields="tableContactus.fields" :items="tableContactus.items" :current-page="pagingContactus.page" :sort-by.sync="pagingContactus.sortBy" :sort-desc.sync="pagingContactus.sortDesc" :sort-direction="pagingContactus.sortDirection" bordered striped hover responsive>
              <template #cell(no)="row">
                {{ (pagingContactus.perPage * (pagingContactus.page-1)) + row.index + 1 }}
              </template>
              <template #cell(content)="row">
                <div v-html="row.item.content" class="px-5" />
              </template>
              <template #cell(action)="row">
                <b-button size="sm" variant="info" @click="doViewContactus(row.item.id)" class="ml-0 ml-sm-3 mt-2 mt-sm-0" title="View">
                  <b-icon icon="eye" class="mx-1"/>
                </b-button>
                <b-button size="sm" variant="danger" @click="doDeleteContactus(row.item.id,false)" title="Delete">
                  <b-icon icon="trash" class="mx-1"/>
                </b-button>
              </template>
            </b-table>
          </b-overlay>
        </b-col>
        <b-col sm=12>
          <b-row no-gutters class="w-100">
            <b-col sm=12 md=6 class="d-flex justify-content-center justify-content-sm-start mb-3 mb-sm-0">
              Showing {{pagingContactus.perPage}} data out of {{tableContactus.totalRows}}
            </b-col>
            <b-col sm=12 md=6 class="d-flex justify-content-center justify-content-sm-end">
              <b-pagination v-model="pagingContactus.page" :total-rows="tableContactus.totalRows" :per-page="pagingContactus.perPage"></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-modal title="Contact Form Message" id="cm-modal-form-contact-us" hide-footer size="md" centered>
        <b-row>
          <b-col sm=6>
            <b-form-group label="Name">
              <span>{{formContactus.data.title}}</span>
            </b-form-group>
          </b-col>
          <b-col sm=6>
            <b-form-group label="Email">
              <span>{{formContactus.data.child.email.content}}</span>
            </b-form-group>
          </b-col>
          <b-col sm=6>
            <b-form-group label="Phone">
              <span>{{formContactus.data.child.phone.content}}</span>
            </b-form-group>
          </b-col>
          <b-col sm=6>
            <b-form-group label="Message">
              <span>{{formContactus.data.content}}</span>
            </b-form-group>
          </b-col>
        </b-row>
    </b-modal>
    <b-modal header-bg-variant="warning" centered body-bg-variant="warning" id="cm-modal-delete" size="sm" hide-footer hide-header>
      <div class="text-center">
        <h1 class="text-light">Warning</h1>
        <p class="text-center mb-3 text-light">Are You Sure to Delete this Data ?</p>
        <div class="d-flex justify-content-center">
          <b-button variant="light" @click="$bvModal.hide('cm-modal-delete')">Cancel</b-button>
          <b-button variant="danger" @click="doDeleteContactus(formContactus.deleteId,true)" class="ml-3">Delete!!!</b-button>
        </div>
      </div>
    </b-modal>
  </section>
</div>
</template>

<script>
import {
  validationMixin
} from 'vuelidate'
import {
  required
} from 'vuelidate/lib/validators'
import {
  baseUrl
} from '@/core/config/baseUrl'
import {
  SET_BREADCRUMB
} from "@/core/services/store/breadcrumbs.module";
export default {
  data() {
    return {
      formNewsletter: {
        loading: false,
        data: {
          id: null,
          image: null,
          title: null,
          content: null,
          id_parent: null,
          type: 'SUBSCRIBE ITEM',
        },
        deleteId: null
      },
      tableNewsletter: {
        items: [],
        fields: [{
            key: 'no',
            label: 'No',
            sortable: false,
            class: 'w-5 text-center'
          },
          {
            key: 'content',
            label: 'Email',
            thClass: 'text-center',
            tdClass: ''
          },
          {
            key: 'action',
            label: 'Action',
            sortable: false,
            class: 'text-center w-10'
          }
        ],
        totalRows: 0,
        loading: false
      },
      pagingNewsletter: {
        page: 1,
        perPage: 10,
        search: '',
        sortBy: 'post.title',
        sortDesc: false,
        sortDirection: 'asc',
        type: 'SUBSCRIBE ITEM'
      },
      formContactus: {
        loading: false,
        data: {
          id: null,
          image: null,
          title: null,
          content: null,
          id_parent: null,
          type: 'CONTACT US MESSAGE ITEM',
          child: {
            email: {
              id: null,
              image: null,
              title: null,
              content: null,
              id_parent: null,
              type: 'CONTACT US MESSAGE EMAIL ITEM',
            },
            phone: {
              id: null,
              image: null,
              title: null,
              content: null,
              id_parent: null,
              type: 'CONTACT US MESSAGE PHONE ITEM',
            }
          }
        },
        deleteId: null
      },
      tableContactus: {
        items: [],
        fields: [{
            key: 'no',
            label: 'No',
            sortable: false,
            class: 'w-5 text-center'
          },
          {
            key: 'title',
            label: 'Nama',
            thClass: 'text-center',
            tdClass: ''
          },
          {
            key: 'content',
            label: 'Pesan',
            thClass: 'text-center',
            tdClass: ''
          },
          {
            key: 'action',
            label: 'Action',
            sortable: false,
            class: 'text-center w-10'
          }
        ],
        totalRows: 0,
        loading: false
      },
      pagingContactus: {
        page: 1,
        perPage: 10,
        search: '',
        sortBy: 'post.title',
        sortDesc: false,
        sortDirection: 'asc',
        type: 'CONTACT US MESSAGE ITEM'
      },
      opt: {
        perPage: [10, 20, 30, 40, 50, 100, 200],
      },
    }
  },
  methods: {
    doExportnewsletter() {
      let payload = {
        page: 1,
        perPage: '~',
        search: '',
        sortBy: 'post.title',
        sortDesc: false,
        sortDirection: 'asc',
        type: 'SUBSCRIBE ITEM'
      }
      let url = baseUrl + `/api/post`
      this.fetchData(url, 'POST', payload)
        .then(res => {
          if (res.status) {
            let itemExport = []
            res.data.map((x) => {
              let data = {
                email: x.content
              }
              itemExport.push(data)
            })
            let headers = {
              email: 'Email',
            };
            this.exportCSVFile(headers, itemExport, 'Newsletter-Export')
          } else {
            this.notif('Failed', res.msg, 'danger')
          }
        })
        .catch(e => {
          console.log(e)
          this.notif('Failed', 'List:Internal server error', 'danger')
        });
    },
    doLoadNewsletter() {
      this.tableNewsletter.loading = true
      let payload = this.pagingNewsletter
      payload.sortDirection = this.pagingNewsletter.sortDesc ? 'asc' : 'desc'
      let url = baseUrl + `/api/post`
      this.fetchData(url, 'POST', payload)
        .then(res => {
          if (res.status) {
            this.tableNewsletter.items = res.data
            this.tableNewsletter.totalRows = res.total
          } else {
            this.notif('Failed', res.msg, 'danger')
          }
          this.tableNewsletter.loading = false
        })
        .catch(e => {
          this.tableNewsletter.loading = false
          console.log(e)
          this.notif('Failed', 'List:Internal server error', 'danger')
        });
    },
    doDeleteNewsletter(id, promt) {
      if (promt) {
      console.log('newsletter')
        let url = baseUrl + `/api/post/delete`
        let data = {
          id: id
        }
        this.fetchData(url, 'POST', data)
          .then(res => {
            if (res.status) {
              this.notif('Success', res.msg, 'success')
              this.doLoadNewsletter()
              this.$bvModal.hide('cm-modal-delete-newsletter')
            } else {
              this.notif('Error', res.msg, 'danger')
              this.doLoadNewsletter()
              this.$bvModal.hide('cm-modal-delete-newsletter')
            }
          })
          .catch(e => {
            console.log(e)
            this.notif('Failed', 'Delete:Internal server error', 'danger')
          });
      } else {
        this.formNewsletter.deleteId = id
        this.$bvModal.show('cm-modal-delete-newsletter')
      }
    },
    doLoadContactus() {
      this.tableContactus.loading = true
      let payload = this.pagingContactus
      payload.sortDirection = this.pagingContactus.sortDesc ? 'asc' : 'desc'
      let url = baseUrl + `/api/post`
      this.fetchData(url, 'POST', payload)
        .then(res => {
          if (res.status) {
            this.tableContactus.items = res.data
            this.tableContactus.totalRows = res.total
          } else {
            this.notif('Failed', res.msg, 'danger')
          }
          this.tableContactus.loading = false
        })
        .catch(e => {
          this.tableContactus.loading = false
          console.log(e)
          this.notif('Failed', 'List:Internal server error', 'danger')
        });
    },
    doViewContactus(id) {
      let url = baseUrl + `/api/post/get`
      let data = {
        id: id
      }
      this.fetchData(url, 'POST', data)
        .then(res => {
          let resData = res.data
          this.formContactus.data.id = resData.id
          this.formContactus.data.image = resData.image
          this.formContactus.data.title = resData.title
          this.formContactus.data.content = resData.content
          this.formContactus.data.id_parent = resData.id_parent
          this.formContactus.data.type = 'CONTACT US MESSAGE ITEM'
          resData.child.map((x) => {
            switch (x.type) {
              case 'CONTACT US MESSAGE EMAIL ITEM':
                this.formContactus.data.child.email = x
                break;
              case 'CONTACT US MESSAGE PHONE ITEM':
                this.formContactus.data.child.phone = x
                break;
            }
          })
          this.$bvModal.show('cm-modal-form-contact-us')
        })
        .catch(e => {
          console.log(e)
          this.notif('Failed', 'Get:Internal server error', 'danger')
        });
    },
    doDeleteContactus(id, promt) {
      if (promt) {
        let url = baseUrl + `/api/post/delete`
        let data = {
          id: id
        }
        this.fetchData(url, 'POST', data)
          .then(res => {
            if (res.status) {
              this.notif('Success', res.msg, 'success')
              this.doLoadContactus()
              this.$bvModal.hide('cm-modal-delete')
            } else {
              this.notif('Error', res.msg, 'danger')
              this.doLoadContactus()
              this.$bvModal.hide('cm-modal-delete')
            }
          })
          .catch(e => {
            console.log(e)
            this.notif('Failed', 'Delete:Internal server error', 'danger')
          });
      } else {
        this.formContactus.deleteId = id
        this.$bvModal.show('cm-modal-delete')
      }
    },
    fetchData(url, type, data) {
      let params = {
        method: type,
        headers: {
          'Content-Type': 'application/json',
        }
      }
      if (data)
        params.body = JSON.stringify(data)
      return fetch(
          url, params
        )
        .then(response => response.json())
    },
    convertToCSV(objArray) {
      var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      var str = '';

      for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
          if (line != '') line += ','

          line += array[i][index];
        }

        str += line + '\r\n';
      }

      return str;
    },
    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = this.convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

      var blob = new Blob([csv], {
        type: 'text/csv;charset=utf-8;'
      });
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    notif(title, msg, type) {
      this.$bvToast.toast(msg, {
        title: title,
        autoHideDelay: 5000,
        variant: type,
        solid: true,
        toaster: 'b-toaster-bottom-left'
      })
    },
  },
  watch: {
    pagingNewsletter: {
      handler() {
        this.doLoadNewsletter()
      },
      deep: true
    },
    pagingContactus: {
      handler() {
        this.doLoadContactus()
      },
      deep: true
    }
  },
  mounted() {
    this.doLoadNewsletter()
    this.doLoadContactus()
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Dashboard"
    }]);
  },
}
</script>

<style lang="scss">
.cm-delete-img {
  position: absolute;
  top: -10px;
  right: 0px;
  background: red;
  color: white;
  font-size: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  transform: rotate(45deg);
  cursor: pointer;
}

.project-caption {
  padding: 10px;
  border: 1px dashed grey;

  h6,
  p {
    color: white;
    text-shadow: 1px 1px grey;
  }
}

.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.modal-dialog.modal-xxl {
  max-width: 90%;
}

.project-img {
  div[role="group"] {
    position: relative;
  }
}
</style>
